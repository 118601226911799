import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';

const ThankYouRefillPage = () => (
  <Layout title="Thank You">
    <section className="thank-you-page">
      <Grid>
        <GridItem>
          <h2>Thank you, your online refill has been submitted.</h2>
          <p>
            ZooPharm Customer Service may be reached at (866) 823-9314,
            Monday - Friday, 8.00 - 5.00 Mountain Time Zone.
          </p>
          <Link to="/" className="c-button--primary">
            Go back to the homepage
          </Link>
        </GridItem>
      </Grid>
    </section>
  </Layout>
);

export default ThankYouRefillPage;
